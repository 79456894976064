/* NAV CSS starts here*/
.transactionHeader{
    background-color:#00425F;
    padding-top: 10%;
    padding-bottom: 5%;
}
@media (max-width: 450px) {
    .transactionHeader{
        padding-top: 25%;
    }
}
.navContainer{
    display: flex;
    align-items: center;
    overflow-x: hidden;
    justify-content: space-between;
    padding: 1%  40px;
 }
 .logoContainer{
    width: 40%;
 }
 .navLogoContainer{ 
    width: auto
 } 
 .navLogin {
     font-family: 'AvertaRegular';
     text-transform: none;
     font-size: 0.9rem;
     line-height: 1.4rem;
     border: 1px solid #479FC8;
     border-radius: 0.4rem;
     text-decoration: none;
     color: #479FC8;
     padding: 0.9rem 1.4rem;
 }
 /*Nav CSS Ends Here*/

/*###################################################*/

/*Transaction Hero starts here*/

.transactionText{
    margin: 0 auto;
}

.transactionText1{
    font-family: 'AvertaRegular';
    font-style: normal;
    font-weight: 700;
    font-size: 2rem;
    line-height: 3rem;
    text-align: center;
    color: #FFFFFF;
}

/*Transaction Hero Ends here */

/*##############################################*/

/*Transaction main starts here*/
.transactionMain{
    padding: 0 40px;
    background-color: #F3F4F6;
}

.transactionInnerMain{
    width: 100%;
    display: flex;
    padding: 20px 0;
    justify-content: space-between;
}
.transactionInner1{
    display: flex;
    width: 42%;
    justify-content: space-between;
    background: #F9FAFB;
    box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);
    border-radius: 8px 8px 0px 0px;
}
.transactionInner2{
    width: 30%;
    background: #F9FAFB;
    box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);
    border-radius: 8px 8px 0px 0px;
    padding: 1%;
}
.transactionInner3{
     width:25%;
}
.paymentCategoryHeader{
    background: #F9FAFB;
    box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);
    border-radius: 8px 8px 0px 0px;
}
.transactionInnersecond, .transactionInnerfirst{
    width: 45%;
}
.transactionInnersecondHeader{
    display: flex;
    align-items: center;
    padding: 20px 0;
    border-bottom: 1px solid #F3F5F6;
}
.transactionInnersecondHeader button{
    outline: none;
    border: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-left: auto;
}
.billerSearch{
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #F3F5F6;
}
.billerContent{
    display: flex;
    align-items: center;
    outline: none;
    border: none;
    background-color: transparent;
    font-family: 'AvertaRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 0.9rem;
    line-height: 1.5rem;
    color: #353F50;
    cursor: pointer;
    padding: 10px 0;
    border-bottom: 1px solid #F3F5F6;
    width: 100%;
    transition: 0.5s;
}
.billerContent:hover{
    background-color: #F3F5F6;
}
.billerSearch input[type=text]{
    outline: none;
    outline: none;
    border: none;
    font-family: 'AvertaRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 0.9rem;
    color: #848F9F;
    line-height: 24px;
    background-color: transparent;
}

.billersContainers{
    /* display: flex;
    gap: 30px;
    flex-wrap: wrap;
    margin-top: 10px; */
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(auto, 1fr);
    gap: 20px;
    padding-left: 30px;
    margin-top: 20px;
}
.categoriesHeader{
    font-family: 'AvertaRegular';
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.3rem;
    color: #353F50;
    
}
.transactionInner2Main{
    margin-top: 40%;
    margin-left: 25%;
}
.billerLink{
    outline: none;
    border: none;
    text-decoration: none;
    display: grid;
    gap:5px;
    place-items: center;
    background-color: #FFF;
    cursor: pointer;
    font-family: 'AvertaSemiBold';
    font-style: normal;
    font-weight: 600;
    font-size: 0.9rem;
    line-height: 20px;
    text-align: center;
    color: #353F50;
}

.billerLink img{
    width: 50px;
    height: 50px;
}
.paymentCategoryHeader p{
    font-family: 'AvertaBold';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #1F2126;
}
@media (max-width: 600px) {
    .paymentCategoryHeader {
        width: 100%;
    }
    .billersContainers{
        width: 100%;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(auto, 1fr);
        margin: 30px 0;
        justify-content: space-around;
        gap: 20px;
    }
    .transactionMain{
        padding: 0 20px;
    }
    .transactionInner3{
        display: none;
    }
}
/*Transaction main ends here*/

/*##############################################*/

/*Transaction Page starts here*/
@media (max-width: 600px) {
    .sideContainer{
        display: none;
    }
}
.sideContainer{
    max-width: 23rem;
    border-radius: 8px;
    border: 1px solid #E1E6ED;
    padding: 5%;
    background: #F9FAFB;
box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);
border-radius: 8px 8px 0px 0px;
}
.sideHeader{
    font-family: 'AvertaRegular';
    font-style: normal;
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 1.5rem;
    color: #353F50;
}
.sideText{
    font-family: 'AvertaRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 0.99rem;
    line-height: 1.2rem;
    color: #5F738C;
    margin: 10px 0;
}
.sideContainer1{
    display: flex;
    flex-direction: column;
}
.sideContainer2{
    display: flex;
    text-decoration: none;
    align-items: flex-start;
    border: 1px solid #F3F4F6;
    border-radius: 8px;
    padding: 5%;
    margin: 15px 0;
}
.sideTextContainer{
    margin-left: 8px;
}
.sideContainer2Text1{
    font-family: 'AvertaRegular';
    font-style: normal;
    font-weight: 600;
    font-size: 1.1rem;
    line-height: 1.5rem;
    color: #0275D8;
}

.sideContainer2Text2{
    font-family: 'AvertaRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.2rem;
    color: #5F738C;
    margin-top: 10px;
}