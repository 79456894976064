*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
html {
   font-size: 93.75%;  /* 15px  */
}
@media (max-width: 800px) {
  html{
    font-size: 65%;
  }
}
@font-face {
  font-family: 'AvertaBlack';
  src: local('AvertaBlack'), url(./Fonts/Averta-Black.otf) format('opentype');
}

@font-face {
  font-family: 'BlackItalic';
  src: local('BlackItalic'), url(./Fonts/Averta-BlackItalic.otf) format('opentype');
}
@font-face {
  font-family: 'AvertaBold';
  src: local('AvertaBold'), url(./Fonts/Averta-Bold.otf) format('opentype');
}
@font-face {
  font-family: 'AvertaBoldItalic';
  src: local('AvertaBoldItalic'), url(./Fonts/Averta-BoldItalic.otf) format('opentype');
}
@font-face {
  font-family: 'AvertaExtraBold';
  src: local('AvertaExtraBold'), url(./Fonts/Averta-ExtraBold.otf) format('opentype');
}
@font-face {
  font-family: 'AvertaExtraBoldItalic';
  src: local('AvertaExtraBoldItalic'), url(./Fonts/Averta-ExtraBoldItalic.otf) format('opentype');
}
@font-face {
  font-family: 'AvertaExtraThin';
  src: local('AvertaExtraThin'), url(./Fonts/Averta-Extrathin.otf) format('opentype');
}
@font-face {
  font-family: 'AvertaExtraThinItalic';
  src: local('AvertaExtraThinItalic'), url(./Fonts/Averta-ExtrathinItalic.otf) format('opentype');
}
@font-face {
  font-family: 'AvertaLight';
  src: local('AvertaLight'), url(./Fonts/Averta-Light.otf) format('opentype');
}
@font-face {
  font-family: 'AvertaLightItalic';
  src: local('AvertaLightItalic'), url(./Fonts/Averta-LightItalic.otf) format('opentype');
}
@font-face {
  font-family: 'AvertaRegular';
  src: local('AvertaRegular'), url(./Fonts/Averta-Regular.otf) format('opentype');
}
@font-face {
  font-family: 'AvertaRegularItalic';
  src: local('AvertaRegularItalic'), url(./Fonts/Averta-RegularItalic.otf) format('opentype');
}
@font-face {
  font-family: 'AvertaSemiBold';
  src: local('AvertaSemiBold'), url(./Fonts/Averta-Semibold.otf) format('opentype');
}
@font-face {
  font-family: 'AvertaSemiBoldItalic';
  src: local('AvertaSemiBoldItalic'), url(./Fonts/Averta-SemiboldItalic.otf) format('opentype');
}
@font-face {
  font-family: 'AvertaThin';
  src: local('AvertaThin'), url(./Fonts/Averta-Thin.otf) format('opentype');
}
@font-face {
  font-family: 'AvertaThinItalic';
  src: local('AvertaThinItalic'), url(./Fonts/Averta-ThinItalic.otf) format('opentype');
}
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }

  .page_layout {
    transition: margin-left .3s;
    background: #f5f5f5;
    position: relative;
    margin-left: 56px;
    height: 100vh;
    left: 0;
    top: 0;
    overflow: auto;
  }

  .page_title {
    color: #616161 !important;
    font-weight: 500 !important;
  }

  .page_body {
    margin-top: 56px !important;
  }

  .menu_block {
    padding: 0px 16px;
    position: relative;
  }

  .date_filter {
    color: #bdbdbd !important;
    height: 100% !important;
    font-size: 2em !important;
    margin-right: 4px !important;
  }

  .form_container {
    flex-grow: 1;
    color: #696b74;
  }

  .form_card {
    border-top: 3px solid #019ee2;
  }

  .form_card .MuiCircularProgress-root {
    margin-left: 15px;
  }

  .form_card_txtTitle {
    font-size: 1.5rem !important;
    font-weight: 700 !important;
    color: #01425e;
  }

  .table_ref {
    color: #03A9F4 !important;
    font-weight: 500 !important;
  }

  .form_card_content {
    padding-bottom: 0 !important;
  }

  .sub_nav_menu {
    border-bottom: 1px solid rgb(221, 221, 221) !important;
    background: transparent !important;
    border-radius: 0px;
    margin-top: 10px;
    margin-bottom: 5px;
  }

  .sub_nav_menu .MuiTabs-root {
    min-height: 0px;
  }

  .sub_nav_menu .MuiTab-root {
    text-transform: capitalize;
    min-width: 100px;
    margin-right: 10px;
  }

  .sub_nav_menu .PrivateTabIndicator-root-418 {
    height: 2.3px;
  }

  .money {
    font-weight: 500;
  }

  .MuiAppBar-colorPrimary {
    background-color: #01425E !important;
  }

  .MuiSnackbarContent-root {
    max-width: 40em;
    flex-wrap: nowrap !important;
  }

  .MuiCircularProgress-root {
    margin-right: 6px;
  }

  ::-webkit-scrollbar {
    height: 7px;
    width: 7px;
  }

  ::-webkit-scrollbar-track {
    background: rgba(221, 221, 221, 0.774);
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(128, 128, 128, 0.774);
  }

  .user_blocking_container {
    position: absolute;
    z-index: 1000;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
  }

 .user_blocking_container.error {
    background: rgba(255, 255, 255, 0.99);
    padding: 4% 0;
  }

  .user_blocking_container.loading {
    background: rgba(255, 255, 255, 0.99);
  }

  .user_blocking_container.loading.dim {
    background: rgba(0, 0, 0, 0.68);
  }

  .user_blocking_container.loading.transparent {
    background: rgba(255, 255, 255, 0.7);
  }

  .center_page, .custom_loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .custom_loader_container {
    position: fixed;
    padding: 10px;
    z-index: 9999;
    height: 100%;
    width: 100%;
    left: 0;
  }

  .custom_loader > div {
    background: #019EE2;
    display: inline-block;
    width: 0.9rem;
    height: 0.9rem;
    border-radius: 50%;
    margin: 5px 2px;
    animation: dimmer 1s infinite ease-in-out;
  }

  .custom_loader > div:nth-child(1) {
    animation-delay: -0.15s;
  }

  .custom_loader > div:nth-child(2) {
    animation-delay: -0.30s;
  }

  @-webkit-keyframes dimmer {
    0%, 80%, 100% {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1)
    }
    40% {
        -webkit-transform: scale(0);
        -ms-transform: scale(0);
        transform: scale(0);
    }
  }

  @keyframes dimmer {
    0%, 80%, 100% {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1)
    }
    40% {
        -webkit-transform: scale(0);
        -ms-transform: scale(0);
        transform: scale(0);
    }
  }

  .btn {
    text-transform: inherit !important;
  }

  .block_container {
    position: relative !important;
  }

  .custom_block {
    margin-bottom: 24px !important;
  }

  .main_root {
    flex-grow: 1;
    margin-top: 6.5vh;
    margin-bottom: 1.5vh;
    padding-top: 12vh;
    padding-bottom: 10vh;
  }

  .black_btn {
    background: #2a2525 !important;
    color: #fff !important;
  }

  .black_btn:hover{
    background: rgba(42, 37, 37, 0.85) !important;
  }

  .dark_blue_btn {
    background: #01425E !important;
    color: #fff !important;
  }

  .dark_blue_btn:hover{
    background: rgba(1, 66, 94, 0.753) !important;
  }

  .mouse_pointer {
      cursor: pointer !important;
  }

  .mouse_move {
    cursor: move !important;
  }

  .table_container {
    position: relative !important;
    overflow: auto;
  }

  .table_empty {
    color: rgba(0, 0, 0, 0.4);
  }

  .table_filter_chips {
    margin-left: 1em !important;
  }

  .custom_table .MuiTableCell-root {
    white-space: nowrap;
  }

  .custom_table .MuiTableCell-head {
    font-size: 0.8rem;
    color: rgba(0, 0, 0, 0.4);
  }

  .custom_table .MuiTableBody-root .MuiTableRow-root:hover {
    background: rgba(1, 158, 226, 0.05);
  }

  .custom_table.bg_header .MuiTableHead-root {
    background: #FAFAFA;
  }

  .custom_table.normal_wrap .MuiTableCell-root {
    white-space: normal;
  }


  .table_scrollLeft .MuiTableCell-body:first-child {
    left: 0;
    z-index: 1;
    position: sticky;
    background-color: #ffffff;
    box-shadow: 3px 0px 7px -2px #dbd7d7;
  }

  .table_scrollLeft .MuiTableCell-head:first-child {
    left: 0;
    z-index: 9;
    position: sticky;
    background-color: #fafafa;
    box-shadow: 3px 0px 7px -2px #dbd7d7;
  }

  .table_scrollRight .MuiTableCell-body:last-child {
    right: 0;
    z-index: 1;
    position: sticky;
    background-color: #ffffff;
    box-shadow: -3px 0px 7px -2px #dbd7d7;
  }

  .table_scrollRight .MuiTableCell-head:last-child {
    right: 0;
    z-index: 9;
    position: sticky;
    background-color: #fafafa;
    box-shadow: -3px 0px 7px -2px #dbd7d7;
  }

  .visibility_icon .MuiSvgIcon-root {
    height: .7em;
  }

  .form_btn {
      margin-top: 10px;
      margin-bottom: 4px;
  }

  .dialog_form_root {
    padding-top: 8px;
    padding-bottom: 20px;
  }

  .dialog_form_root .MuiFormControl-root {
    margin-bottom: 10px;
  }

  .text_right {
    text-align: right !important;
  }

  .text_center {
    text-align: center !important;
  }

  .menu_arrow {
    color: #a7b1c2;
  }

  .active_menu {
    color: #FFFFFF !important;
  }

  .active_menu .MuiListItemIcon-root, .active_menu .MuiListItemText-root, .active_menu .MuiSvgIcon-root {
    color: #FFFFFF !important;
  }

  .active_link {
    border-left: 3px solid #ffffff !important;
    background-color: rgba(0, 0, 0, 0.08) !important;
    color: #FFFFFF !important;
  }

  .active_link .MuiListItemIcon-root {
    color: #FFFFFF !important;
  }

  .active_link .MuiListItemText-root {
    color: #ffffff !important;
  }

  .report_chip {
    margin-right: 8px;
    margin-bottom: 4px;
  }

  .download_report_notification .MuiSnackbarContent-root {
    padding: 0;
  }

  .download_report_notification .MuiSnackbarContent-message {
    padding: 0;
  }

  .download_report_notification .MuiExpansionPanelSummary-root {
    background:#019EE2;
    color: #FFF;
  }


  .list_div_block_200 {
    width: 100%;
    max-height: 200px;
    overflow: auto;
  }

  .greyed_content {
    opacity: 0.2;
    cursor: not-allowed;
  }

  .medium_screen_2 {
    width: 49%;
  }

  .w_100 {
    width: 100%;
  }

  .h_100 {
    height: 100%;
  }

  .plain_dropdown_label {
    color: #777;
    padding-left: 12px;
    font-size: 0.85rem;
  }

  .plain_dropdown {
    font-weight: 700;
    width: auto !important;
    margin-right: 12px !important;
    vertical-align: unset !important;
  }

  .plain_dropdown .MuiInputBase-inputSelect {
    color: #777;
    padding-right: 20px;
    padding-left: 5px;
    font-size: 0.85rem;
  }

  .plain_dropdown .MuiInput-underline::before {
    border: none;
  }

  .plain_dropdown .MuiInput-underline::after{
    border: none;
  }

  .plain_dropdown .MuiInput-underline:hover:not(.Mui-disabled):before {
    border: none;
  }

  .list_hover:hover {
    background: rgba(1, 158, 226, 0.05);
  }

  .plain_label {
    color: #ccc;
  }

  .custom_search_fill {
    display: inline-flex;
    width: 40%;
  }

  .custom_search_fill .MuiInputBase-root {
    width: 100%;
  }

  .item_card {
    margin-right: 20px;
    margin-bottom: 20px;
    cursor: pointer;
    height: 85px;
  }

  .item_card:hover{
    box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.28);
  }

  .item_content > div {
    height: 52px;
  }

  .item_content > div:last-child {
    overflow: auto;
  }

  .item_img {
    border: 1px solid #F2F2F2;
    background: #FAFAFA;
    margin-right: 12px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .item_img img {
    max-width: 90%;
    max-height: 100%;
  }

  .item_title {
    display: block;
    font-size: 0.8rem;
    font-weight: 500;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    letter-spacing: 0.0333em;
  }

  .item_subtitle {
    display: block;
    font-size: 0.7rem;
    color: #777;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    letter-spacing: 0.0333em;
  }

  .drawer_list .MuiList-padding {
    padding-top: 0;
    padding-bottom: 0;
  }

  .drawer_list .MuiDrawer-paper{
    background: #01425E;
  }

  .drawer_list .MuiListItem-gutters {
    padding-right: 80px;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  .drawer_list .MuiListItem-gutters:hover {
    background: rgba(230, 229, 229, 0.76);
  }

  .drawer_list .MuiListItem-gutters:hover .MuiTypography-colorTextSecondary {
    color: #01425E;
  }

  .drawer_list .MuiTypography-colorTextSecondary {
    color: rgba(230, 229, 229, 0.76);
  }

  .drawer_list .MuiListItem-gutters:first-child,
  .drawer_list .MuiListItem-gutters:first-child:hover {
    background: #01344a;
    cursor: default;
  }

  .drawer_list .MuiTypography-root.MuiListItemText-primary.MuiTypography-body1,
  .drawer_list .MuiListItemIcon-root {
    color: rgba(230, 229, 229, 0.9);
    font-weight: 700;
  }

  .sidenav_scrollbar::-webkit-scrollbar {
    background-color: '#a7b1c2';
    height: 4px;
    width: 2px;
  }

  .sidenav_scrollbar::-webkit-scrollbar-thumb {
    background: #a7b1c2 !important;
  }

  .sidenav_scrollbar::-webkit-scrollbar-track {
    background: none;
  }

  .hover_list_item {
    display: flex;
  }

  .hover_menu {
    background: #01425E;
    color: #a7b1c2;
    position: fixed;
    margin-left: 100px;
    padding: 8px;
}

  .btn_icon_right {
    margin-left: 8px;
  }

  .border_radius_0 {
    border-radius: 0 !important;
  }

  .cursor_pointy {
    cursor: pointer;
  }

  .display_none {
    display: none !important;
  }

  .table_row_label {
    font-weight: 600 !important;
    font-size: 0.875rem !important;
    padding: 3.5px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    border-radius: 5px;
    text-transform: uppercase;
    box-shadow: 2px 2px 4px 0px #d2caca;
}

  .table_status {
    background: #F1F2F3;
    padding: 5px 7px;
    border-radius: 12px;
  }

  .table_status > svg {
    font-size: 0.7em;
  }

  .table_status.success > svg {
    color: #08a508;
  }

  .table_status.error > svg {
    color: #ec0202;
  }

  .login_slideshow {
    height:100%;
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }

  .item-slide {
    padding: 80px 0;
    width: 900px;
  }

  .item-slide h1 {
    text-transform: uppercase;
  }

  .payment_card_avatar {
    width: 35px !important;
    height: 20px !important;
    background: #FFFFFF !important;
  }

  .payment_card_avatar .MuiAvatar-fallback {
    display: none;
  }

  .wrap-control .btn {
      margin: 0 5px;
      width: 80px;
      cursor: pointer;

  }
  .clearfix:after{
      clear: both;
      content: ".";
      display: block;
      width: 0px;
      height: 0px;
  }

  .indicators {
    padding: 0;
    list-style: none;
    text-align: center;
    position: absolute;
    bottom: 190px;
  }
  .indicators li {
      cursor: pointer;
      display: none;
      margin: 0 10px;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      border: 2px solid blue;
      background: white;
  }

  .indicators li:active {
    background: blue;
  }

  .timeout-notify {
    display: flex;
    align-items: center;
  }

  .timeout-notify .MuiSvgIcon-root {
    padding-right: 10px;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }

  @media (max-width: 767px) {
    .medium_screen_2 {
      width: 100%;
      margin-top: 4%;
    }

    .custom_search_fill {
      width: 80%;
    }
  }

  @media (max-width: 1199px) {
    .page_layout {
      margin-left: 0px !important;
    }

    .active_menu {
      color: #01425e !important;
    }

    .active_menu .MuiListItemIcon-root, .active_menu .MuiListItemText-root, .active_menu .MuiSvgIcon-root {
      color: #01425e !important;
    }

    .active_link {
      border-left: 3px solid #ffffff !important;
      background-color: rgba(0, 0, 0, 0.08) !important;
      color: #FFFFFF !important;
    }

    .active_link .MuiListItemIcon-root {
      color: #01425e !important;
    }

    .active_link .MuiListItemText-root {
      color: #01425e !important;
    }
  }

  @media (min-width: 1280px) {
    .main_root .MuiContainer-maxWidthLg {
      max-width: 100%;
    }
  }

  @media (min-width: 600px) {
    .form_card {
      margin: auto 32px;
    }
}
