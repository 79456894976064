/* NAV CSS starts here*/
.navContainer{
   width: 100%;
   height: 80px;
   background-color:#FFF ;
   display: flex;
   align-items: center;
   justify-content: space-between;
   padding: 0 40px;
   position: fixed !important;
   top: 0;
   z-index: 5;
}
.logoContainer{
   width: 40%;
}
.navLogoContainer{ 
   width: auto
} 
.navLogin {
    font-family: 'AvertaRegular';
    text-transform: none;
    font-size: 0.9rem;
    line-height: 1.4rem;
    border: 1px solid #0275D8;
    border-radius: 0.4rem;
    text-decoration: none;
    color: #0275D8;
    padding: 0.9rem 1.9rem;
    letter-spacing: 1px;
}
.navStatus{
    font-family: 'AvertaRegular';
    font-size: 1.2rem;
    line-height: 1.5rem;
    border-radius: 0.4rem;
    text-decoration: none;
    color: #0275D8;
    letter-spacing: 1px;
    background: transparent;
    outline: none;
    border: none;
}
.navLogoContainerMobile{
    display: none;
}
.mobileNavDropdown{
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 999;
    right: 20px;
    top:60px;
    background: #FFFFFF;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 2% 4%;
}
.mobileNavDropdown a{
    font-family: 'AvertaRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #353F50;
    text-decoration: none;
    margin: 10px 0;
}
.mobileNavButton{
    outline: none;
    border: none;
    background-color: transparent;
}
@media (max-width: 500px) {
    .navLogoContainer, .heroContainerText::after, .heroContainerText::before, .first::before, .wrapper::after,.wrapper::before, .fifth::after, .fifth::before{
        display: none;
    }
    .navContainer{
    position: fixed !important;
    height: 60px;
    padding: 0 20px;
    }
    .logoContainer img{
        width: 96px;
        height: 24px;
    }
    .navLogoContainerMobile{
        display: flex;
        align-items: center;
        gap: 10px;
    }
    
}
/*Nav CSS Ends Here*/

/*#######################################################*/

/*Hero CSS starts here*/
.heroContainer{
    background-color: #FFF;
    margin-top: 90px;
    display: grid;
    place-items: center;
}
.heroContainerText{
    width: 60%;
}

.heroContainerText .first{
    font-family: 'AvertaRegular';
    font-style: normal;
    font-weight: 600;
    font-size: 1.1rem;
    line-height: 1.5rem;
    color: #479FC8;
    text-align: center;
    margin-bottom: 20px;
}

.wrapper .second, .third{
    font-family: 'AvertaExtraBold';
    font-style: normal;
    font-weight: 800;
    font-size: 4rem;
    line-height: 80px;
    color: #00425F;
    text-align: center;
} 
.formContainer{
    margin: 40px auto;
}
.wrapper{
    box-sizing: content-box;
    height: 80px;
    display: flex;
    justify-content: center;
}

.words{
    overflow: hidden;
}
.formInput{
    display: flex;
    align-items: center;
}    
.formBox{
    border-radius: 48px;
    padding: 1% 20px;
    background-color: #F3F4F6;
    width: 35%;
    margin: 0 auto;
}
.formControl{
    margin-left: auto !important;
}
.formBoxOpen{
    max-height: 200px;
    background: #FFFFFF;
    border-radius: 18px;
    width: 35%;
    padding: 1% 20px;
    margin: 0 auto;
    overflow-y: scroll;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.formBoxOpen::-webkit-scrollbar{
    display: none !important;
}
.searchResultContainer{
    margin-top: 15px;
    border-top: 1px solid #F3F5F6;
}
.searchResultsLinks a{
    display: flex;
    justify-content: space-between;
    text-decoration: none;
    margin: 10px 0;
}
.errorResults{
    font-family: 'AvertaRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 0.9rem;
    line-height: 19px;
    color: #5F738C;
    margin: 10px 0;
    text-align: center;
}
.searchResultsLinks a span:nth-of-type(1){
    font-family: 'AvertaRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 1.1rem;
    line-height: 24px;
    color: #353F50;
}
.searchResultsLinks a span:nth-of-type(2){
    font-family: 'AvertaRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 0.9rem;
    line-height: 19px;
    color: #5F738C;
}
.formInput input[type=text]{
    outline: none;
    border: none;
    height: 80%;
    width: 50%;
    font-family: 'AvertaRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 1.1rem;
    color: #353F50;
    line-height: 24px;
    background-color: transparent;
}
.formInput .searchicon{
    width: 20px;
    height: 20px;
    margin-right: 20px;
}
.formInput input[type=text]::placeholder{
    font-family: 'AvertaRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 1.1rem;
    color: #848F9F;
}
.selectBox{
    margin-left: auto;
    outline: none;
    border: none;
    width: 120px;
    font-family: 'AvertaRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 1.1rem;
    color: #353F50;
    line-height: 24px;
    background-color: transparent;
    outline: none;
    border: none;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.selectBox option{
    font-family: 'AvertaRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #353F50;
    margin: 3px;
    cursor: pointer;
    border-radius: 4%;
    padding: 3px;
    -webkit-appearance: none;
    -moz-appearance: none;
}
.fourth{
    font-family: 'AvertaRegular';
    font-style: normal;
    font-weight: 600;
    font-size: 1.3rem;
    line-height: 32px;
    color: #00425F;
    text-align: center;
    margin-bottom: 20px;
}
.fifth{
    font-family: 'AvertaRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 1.3rem;
    line-height: 1.7rem;
    color: #353F50;
    text-align: center;
    width: 70%;
    margin: 0 auto;
}

.words span{
    display: block;
    height: 100%;
    padding-left: 10px;
    font-family: 'AvertaExtraBold';
    font-style: normal;
    font-weight: 800;
    font-size: 4rem;
    line-height: 80px;
    color: #00425F;
    text-align: left;
    animation: spin_words 10s infinite;
}

@keyframes spin_words{
    10%{
        transform: translateY(-112%);
    }
    25%{
        transform: translateY(-100%);
    }
    35%{
        transform: translateY(-212%);
    }
    50%{
        transform: translateY(-200%);
    }
    60%{
        transform: translateY(-312%);
    }
    75%{
        transform: translateY(-300%);
    }
    85%{
        transform: translateY(-412%);
    }
    100%{
        transform: translateY(-400%);
    }
}
.billersContainer{
    display: grid;
    gap: 5px;
    grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
    width: 70%;
    margin: 70px auto;
    justify-content: space-between;
}
.billerLink{
    outline: none;
    border: none;
    text-decoration: none;
    display: grid;
    gap:5px;
    place-items: center;
    background-color: #FFF;
    cursor: pointer;
    font-family: 'AvertaSemiBold';
    font-style: normal;
    font-weight: 600;
    font-size: 0.9rem;
    line-height: 20px;
    text-align: center;
    color: #353F50;
}

.billerLink img{
    width: 50px;
    height: 50px;
}
@media (max-width: 500px) {
    .heroContainerText{
        width: 100%;
    }
    .wrapper .second, .third, .words span{
        font-size: 2.8rem;
    }
    .wrapper{
        box-sizing: content-box !important;
        height: 40px;
    }
    .words span, .wrapper .second, .third{
        line-height: 40px;
    }
    .fifth{
        width: 85%;
        font-size: 1.5rem;
        line-height: 20px;
    }
    .formInput{
        width: 100%;
    }
    .billersContainer{
        width: 100%;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(auto, 1fr);
        margin: 30px 0;
        padding: 0 10px;
    }
    .formContainer{
        padding: 0 20px;
    }
    .formBox{
        width: 100%;
        padding: 15px 15px;
    }
    .formBoxOpen{
        width: 100%;
        padding: 10px 15px;
    }
    .formInput .searchicon{
        width: 15px;
        height: 15px;
        margin-right: 15px;
    }
    .formInput input[type=text]::placeholder{
        font-size: 1.3rem;
    }
    .billerLink{
        font-size: 12px !important;
        line-height: 16px !important;
    }
    .selectBox{
        width: 90px;
        max-width: 50%;
    }
    .selectBox{
        font-size: 1.2rem;
    }

}
@media (min-width:2022px) {
    .heroContainerText::after, .heroContainerText::before, .first::before, .wrapper::after,.wrapper::before, .fifth::after, .fifth::before{
        display: none;
    }
}
@media (min-width: 451px) and (max-width:1024px) {
    .heroContainerText::after, .heroContainerText::before, .first::before, .wrapper::after,.wrapper::before, .fifth::after, .fifth::before{
        display: none;
    }
    .heroContainerText{
        width: 100%;
    }
    .wrapper .second, .third, .words span{
        font-size: 3rem;
    }
    .wrapper{
        box-sizing: content-box !important;
        height: 50px;
    }
    .words span, .wrapper .second, .third{
        line-height: 50px;
    }
    .fifth{
        width: 65%;
        font-size: 1rem;
        line-height: 20px;
    }
    .formInput{
        width: 100%;
        font-size: 1rem;
    }
    .billersContainer{
        width: 100%;
        margin-top: 10px;
    }
    .formContainer{
        padding: 0 20px;
    }
    .formBox, .formBoxOpen{
        width: 80%;
        padding: 10px 20px;
    }
    .selectBox{
        width: 90px;
        max-width: 50%;
    }
    .selectBox{
        font-size: 1.2rem;
    }
}
@media (min-width: 1025px){
    .heroContainerText::after{
        content: url('../../Assets/Ornaments/Ornament3.svg');
        position: absolute;
        left: 0;
        bottom: 200px;
        z-index: 1;
        transform: translate(-50%, -50%);
    }
    .heroContainerText::before{
        content: url('../../Assets/Ornaments/Ornament4.svg');
        position: absolute;
        right: 200px;
        bottom: 500px;
        z-index: 1;
        transform: translate(-50%, -50%);
    }
    .fifth::after{
        content: url('../../Assets/Ornaments/Ornament2.svg');
        position: absolute;
        right: 0;
        bottom: 200px;
        z-index: 1;
    }
    .fifth::before{
        content: url('../../Assets/Ornaments/Ornament1.svg');
        position: absolute;
        right: 210px;
        bottom: 470px;
        z-index: 1;
       
    }
    .wrapper::after{
        content: url('../../Assets/Ornaments/Ornament5.svg');
        position: absolute;
        left: 250px;
        bottom: 270px;
        z-index: 1;
    }
    .wrapper::before{
        content: url('../../Assets/Ornaments/Ornament6.svg');
        position: absolute;
        right: 200px;
        bottom: 450px;
        z-index: 1;
        transform: translate(-50%, -50%);
    }
    .first::before{
        content: url('../../Assets/Ornaments/Ornament7.svg');
        position: absolute;
        left: 200px;
        bottom: 400px;
        z-index: 1;
        transform: translate(-50%, -50%);
    }
    .selectBox{
        width: 120px
    }
}
/*Hero CSS ends here*/

/*#########################################################*/


/*Footer CSS starts Here*/
.footerContainer{
    background-color:#00425F ;
    padding: 9% 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.footerText{
    width: 723px;
}
  .footerTextType{
   font-family:'AvertaBold'; 
   letter-spacing: -0.5px; 
   color:#fff; 
   font-weight:700px; 
   font-size:3rem; 
   text-align:center; 
  }
  .footerBtn{
    display: flex;
    justify-content: center;
    width: 80%;
    margin: 0 auto;
    margin-top: 1rem;
    gap: 15px;
  }
  .footerLinksContainer{
    background-color:#00425F ;
    display: flex;
    padding: 4% 30px;
    border-top: 1px solid rgba(255,255,255, 0.1);
    }
  .footerLinks{
   font-family:'AvertaRegular';
  font-size:0.9rem;
  font-weight:500;
  margin: 4% 0;
  color: #fff;
  cursor: pointer;
  text-decoration: none;
  line-height: 1.6rem;
  }
  .footerLinksHead{
   font-family:'AvertaRegular';
   font-size:0.9rem;
   font-weight:600;
   margin: 5px 0;
   color: #C8D2DF;
   margin-bottom:8px;
   line-height: 1.2rem;
   text-transform: uppercase;
  }
  .footerLink1{
   font-size: 0.9rem;
   font-family: 'AvertaRegular';
   font-style: normal;
   color: #fff;
   background-color: #479FC8;
   padding: 0.9rem 1.9rem;
   border-radius: 8px;
   cursor: pointer;
   text-align: center;
   text-decoration: none;
   line-height: 20px;
   letter-spacing: 1px;
   font-weight: 400;
  }
  .footerLink2{
   font-size: 0.9rem;
   font-family: 'AvertaRegular';
   color: #479FC8;;
   border:1px solid #479FC8;
   padding: 0.9rem 1.9rem;
   border-radius: 8px;
   cursor: pointer;
   text-align: center;
   text-decoration: none;
   letter-spacing: 1px;
   line-height: 20px;
   font-style: normal;
   font-weight: 400;
  }
  .footerLink3{
   font-family: 'AvertaRegular';
   font-weight: 400;
   font-size: 1rem;
   line-height: 0.9rem;
   color: #fff;
  }
  
  .linkContainer1{
      display: flex;
      flex-direction: column;
      width: 20%;
  }
  .linkContainer2{
      display: flex;
      flex-direction: column;
      width: 20%;
  }
  .linkContainer3{
      display: flex;
      flex-direction: column;
      width: 20%;
  }
  .linkContainer4{
      display: flex;
      flex-direction: column;
      width: 27%;
  }
  .footerSocialContainer{
      display: flex;
      background-color:#00425F ;
      align-items: center;
      justify-content: space-between;
      padding: 20px 30px;
      width: 100%;
      border-top: 1px solid rgba(255,255,255, 0.1);
  }
  .socialContainer{
      display: flex;
      gap: 1.5rem;
  }
  .socialContainer a{
   cursor: pointer;
  }
  .accordionContainer{
    background-color:#00425F ;
    padding: 10% 20px;
  }

  .accordionHeader{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0;
  }
  .accordionTitle{
   font-family: 'AvertaRegular';
   font-style: normal;
   font-weight: 600;
   font-size: 1.8rem;
   color: #F9FBFC;
  }
  .accordionLinks{
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
  }
  .accordionLinks a{
   text-decoration: none;
   font-family: 'AvertaLight';
   font-size: 1.3rem;
   font-weight: 400;
   color: #C8D2DF;
   margin: 5px 0;
  }
  .imgRotate{
    transform: rotate(45deg);
  }
  @media (max-width: 800px) {
      
      .footerText{
          width: 100%;
      }
      .footerTextType{
        width: 80%;
        margin: 0 auto;
        text-align: center;
        font-size: 2.5rem;
      }
      .footerLink1, .footerLink2{
        line-height: 24px;
        font-size: 1.2rem;
      }
      .footerLink3{
         font-size: 1.3rem;
         margin: 10% 0;
         margin-top: 4%;
         text-align: center;
      }
      .footerBtn{
          flex-direction: column;
          width: 100%;
      }
      .footerContainer{
          padding: 9% 20px;
         
      }
      .footerSocialContainer{
         flex-direction: column;
         justify-content: center;
         align-items: center; 
         gap: 0;
         padding: 70px 0;
         padding-top: 40px;
      }
      .socialContainer{
        width: 100%;
        gap: 0;
        justify-content: space-around;
      }
  }